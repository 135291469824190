
import queries from '@/queries/searchSuggestions.gql'
import { debounce } from '~/utils'
export default {
  name: 'SearchWithDropdown',
  props: {
    queryName: {
      type: String,
      default: '',
    },
    initial: {
      type: String,
      default: '',
    },
    amount: {
      type: Number,
      default: 6,
    },
    queryVariables: {
      type: [Object, Boolean],
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    initialTitle: {
      type: String,
      default: '',
    },
    initialList: {
      type: Array,
      default: () => [],
    },
    searchEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchDropdownItems: [],
      navigatedSearchDropdownItemIndex: -1,
      searchValue: '',
      isSearchDropdownItemsOpen: false,
      isSearchDropdownItemsFetching: false,
      storedSearchItems: {},
    }
  },
  computed: {
    showInitial() {
      return this.searchValue.length < 2
    },
    dropdownTitle() {
      return this.showInitial ? this.initialTitle : this.title
    },
    dropdownItems() {
      return this.showInitial ? this.initialList : this.searchDropdownItems
    },
  },
  created() {
    if (this.initial) {
      this.searchValue = this.initial
    }
  },
  methods: {
    onFocus() {
      this.isSearchDropdownItemsOpen = true
    },
    onBlur() {
      this.closeDropdown()
    },
    onSearchKeydownDown() {
      if (
        this.dropdownItems.length &&
        this.navigatedSearchDropdownItemIndex < this.dropdownItems.length - 1
      ) {
        const index = this.navigatedSearchDropdownItemIndex + 1
        this.navigatedSearchDropdownItemIndex = index
      }
    },
    onSearchKeydownUp() {
      if (
        this.dropdownItems.length &&
        this.navigatedSearchDropdownItemIndex > -1
      ) {
        const index = this.navigatedSearchDropdownItemIndex - 1
        this.navigatedSearchDropdownItemIndex = index
      }
    },
    onSearchToggle(isOpen) {
      if (!isOpen) {
        this.searchValue = ''
        this.closeDropdown()
        this.searchDropdownItems = []
        this.onSearchSelect('')
      }
    },
    closeDropdown() {
      this.isSearchDropdownItemsOpen = false
      this.navigatedSearchDropdownItemIndex = -1
    },
    formatSearchItems(items = []) {
      return items.reduce(
        (previous, item) => (item.title ? [...previous, item.title] : previous),
        [],
      )
    },
    highlightItem(item) {
      return item
        .toLowerCase()
        .replace(
          this.searchValue.toLowerCase(),
          `<span class="font-normal">${this.searchValue.toLowerCase()}</span>`,
        )
    },
    async fetchItems(title, queryVariables = {}) {
      if (this.queryName === '') return

      try {
        const variables = {
          domainId: this.$config.domainId,
          languageCode: this.$i18n.locale,
          pageId: this.$route.meta.page_id,
          first: this.amount,
          excludeTags: this.$config.tagsToExclude,
          searchByTitle: title,
        }
        Object.assign(variables, queryVariables)
        const {
          data: { list },
        } = await this.$gqlQuery({
          query: queries[this.queryName],
          fetchPolicy: 'no-cache',
          variables,
        })
        return list?.data || []
      } catch (ex) {
        console.log(ex)
        return []
      }
    },
    async getItems(title) {
      const lowerCasedTitle = title.toLowerCase()

      // return items searched before
      const storedItems = this.storedSearchItems[lowerCasedTitle]
      if (storedItems) return storedItems

      // return existig among dropdown options items and store them to do not fetch next time
      const existingItems = this.searchDropdownItems.filter((item) =>
        item.toLowerCase().includes(lowerCasedTitle),
      )
      if (
        this.searchDropdownItems.length > 0 &&
        this.searchDropdownItems.length < 6 &&
        existingItems.length > 0
      ) {
        this.storedSearchItems[lowerCasedTitle] = existingItems
        return existingItems
      }

      // return fetched items and store them to do not fetch next time
      const fetchedItems =
        (await this.fetchItems(lowerCasedTitle, this.queryVariables)) || []
      const formattedItems = this.formatSearchItems(fetchedItems)
      this.storedSearchItems[lowerCasedTitle] = formattedItems
      return formattedItems
    },
    onSearchInput: debounce(async function (value) {
      this.navigatedSearchDropdownItemIndex = -1
      this.isSearchDropdownItemsOpen = true
      this.$emit('input', value)
      if (value.length < 2) return
      this.isSearchDropdownItemsFetching = true
      const dropdownItems = (await this.getItems(value)) || []
      this.isSearchDropdownItemsFetching = false
      this.searchDropdownItems = dropdownItems
    }, 275),
    onSearchSelect(value = this.searchValue) {
      if (value !== this.searchValue) this.searchValue = value
      this.isSearchDropdownItemsOpen = false
      this.$emit('onSelect', value)
    },
  },
}
